// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gaming {
    font-family: 'px', cursive;
    display: flex;
    align-items: center;
    gap: 10px;
}

.some {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333333;
    padding: 10px 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 999;
}

h1 {
    color: #ffffff;
    font-size: 20px;
    margin: 0;
}

.remote-icon {
    width: 30px;
    height: 30px;
}

.links {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 4px;
}

.links a {
    color: #ffffff;
    text-decoration: none;
    font-size: 20px;
    padding: 10px;
    border-radius: 5px;
}

.links a:hover {
    background-color: #ffffff;
    color: #333333;
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
    .gaming {
        font-size: 16px;
    }

    .some {
        padding: 10px 20px;
    }

    h1 {
        font-size: 18px;
    }

    .links {
        gap: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/CSS/MainScreen/Navbar.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,WAAW;AACf;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,sCAAsC;AAC1C;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,SAAS;IACb;AACJ","sourcesContent":[".gaming {\r\n    font-family: 'px', cursive;\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 10px;\r\n}\r\n\r\n.some {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    background-color: #333333;\r\n    padding: 10px 10px;\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    height: 50px;\r\n    z-index: 999;\r\n}\r\n\r\nh1 {\r\n    color: #ffffff;\r\n    font-size: 20px;\r\n    margin: 0;\r\n}\r\n\r\n.remote-icon {\r\n    width: 30px;\r\n    height: 30px;\r\n}\r\n\r\n.links {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    gap: 20px;\r\n    margin: 4px;\r\n}\r\n\r\n.links a {\r\n    color: #ffffff;\r\n    text-decoration: none;\r\n    font-size: 20px;\r\n    padding: 10px;\r\n    border-radius: 5px;\r\n}\r\n\r\n.links a:hover {\r\n    background-color: #ffffff;\r\n    color: #333333;\r\n    transition: background-color 0.3s ease;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .gaming {\r\n        font-size: 16px;\r\n    }\r\n\r\n    .some {\r\n        padding: 10px 20px;\r\n    }\r\n\r\n    h1 {\r\n        font-size: 18px;\r\n    }\r\n\r\n    .links {\r\n        gap: 10px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
