// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/player.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.player_background {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
    .quiz-game-container {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
    }

    .quiz-game-title {
        font-size: 18px;
    }

    .quiz-game-question {
        font-size: 16px;
        margin-top: 20px;
    }

    .quiz-game-options {
        margin: 10px 0;
    }

    .quiz-game-option label {
        font-size: 14px;
    }

    .quiz-game-next-button,
    .quiz-game-restart-button {
        padding: 8px 16px;
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .quiz-game-container {
        width: 80%;
        margin-top: 50px;
        padding: 20px;
    }
}

@media only screen and (min-width: 1024px) {
    .quiz-game-container {
        width: 600px;
        margin-top: calc(50vh - 200px);
        padding: 20px;
    }
}

@media only screen and (min-width: 1200px) {
    .quiz-game-container {
        width: 800px;
    }
}`, "",{"version":3,"sources":["webpack://./src/CSS/GamesProps/Player.css"],"names":[],"mappings":";AACA;IACI,yDAAgD;IAChD,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI;QACI,WAAW;QACX,gBAAgB;QAChB,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,eAAe;IACnB;;IAEA;;QAEI,iBAAiB;QACjB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,UAAU;QACV,gBAAgB;QAChB,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,8BAA8B;QAC9B,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":["\r\n.player_background {\r\n    background-image: url(\"../../assets/player.jpg\");\r\n    background-size: cover;\r\n    background-repeat: no-repeat;\r\n}\r\n\r\n@media only screen and (max-width: 767px) {\r\n    .quiz-game-container {\r\n        width: 100%;\r\n        margin-top: 20px;\r\n        padding: 10px;\r\n    }\r\n\r\n    .quiz-game-title {\r\n        font-size: 18px;\r\n    }\r\n\r\n    .quiz-game-question {\r\n        font-size: 16px;\r\n        margin-top: 20px;\r\n    }\r\n\r\n    .quiz-game-options {\r\n        margin: 10px 0;\r\n    }\r\n\r\n    .quiz-game-option label {\r\n        font-size: 14px;\r\n    }\r\n\r\n    .quiz-game-next-button,\r\n    .quiz-game-restart-button {\r\n        padding: 8px 16px;\r\n        font-size: 14px;\r\n    }\r\n}\r\n\r\n@media only screen and (min-width: 768px) and (max-width: 1023px) {\r\n    .quiz-game-container {\r\n        width: 80%;\r\n        margin-top: 50px;\r\n        padding: 20px;\r\n    }\r\n}\r\n\r\n@media only screen and (min-width: 1024px) {\r\n    .quiz-game-container {\r\n        width: 600px;\r\n        margin-top: calc(50vh - 200px);\r\n        padding: 20px;\r\n    }\r\n}\r\n\r\n@media only screen and (min-width: 1200px) {\r\n    .quiz-game-container {\r\n        width: 800px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
