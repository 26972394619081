// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333333;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
}

.gaming {
    font-family: "px", cursive;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #ffffff;
    font-size: 20px;
    margin: 0;
}

.links {
    display: flex;
    gap: 20px;
    margin: 4px;
}

.navbar-link {
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
}

.navbar-link:hover {
    background-color: #ffffff;
    color: #333333;
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
    .navbar {
        justify-content: center;
        padding: 10px 20px;
    }

    .links {
        flex-direction: column;
        gap: 10px;
    }

    .navbar-link {
        padding: 8px;
        text-align: center;
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/CSS/AdminScreens/AdminMenu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,cAAc;IACd,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,WAAW;AACf;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,sCAAsC;AAC1C;;AAEA;IACI;QACI,uBAAuB;QACvB,kBAAkB;IACtB;;IAEA;QACI,sBAAsB;QACtB,SAAS;IACb;;IAEA;QACI,YAAY;QACZ,kBAAkB;QAClB,WAAW;IACf;AACJ","sourcesContent":[".navbar {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    background-color: #333333;\r\n    padding: 10px;\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    height: 50px;\r\n}\r\n\r\n.gaming {\r\n    font-family: \"px\", cursive;\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 10px;\r\n    color: #ffffff;\r\n    font-size: 20px;\r\n    margin: 0;\r\n}\r\n\r\n.links {\r\n    display: flex;\r\n    gap: 20px;\r\n    margin: 4px;\r\n}\r\n\r\n.navbar-link {\r\n    color: #ffffff;\r\n    text-decoration: none;\r\n    font-size: 16px;\r\n    padding: 10px;\r\n    border-radius: 5px;\r\n}\r\n\r\n.navbar-link:hover {\r\n    background-color: #ffffff;\r\n    color: #333333;\r\n    transition: background-color 0.3s ease;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .navbar {\r\n        justify-content: center;\r\n        padding: 10px 20px;\r\n    }\r\n\r\n    .links {\r\n        flex-direction: column;\r\n        gap: 10px;\r\n    }\r\n\r\n    .navbar-link {\r\n        padding: 8px;\r\n        text-align: center;\r\n        width: 100%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
